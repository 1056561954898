<template>
  <div class="before-apply" @touchmove.prevent>
    <div class="canvas-title">协议签署</div>
    <div class="canvas-box" id="canvas-box">
      <img :src="signature_image" v-if="!show" />
      <div class="canvas-tips">若签名板功能出现异常，请尝试”刷新“页面重试。</div>
      <canvas id="canvas">Canvas画板</canvas>
    </div>
    <div class="page-ft">
      <van-row gutter="20" class="canvas-btn">
        <van-col span="8">
          <van-button plain hairline type="primary" @click.native="clear" round block color="#4B99FF">清除</van-button>
          <!-- <van-button @click.native="clear" block round tplain hairline>清除</van-button> -->
        </van-col>
        <van-col span="16">
          <van-button :disabled="submitBtnDisabled" @click.native="save" block round color="#4B99FF">提交</van-button>
        </van-col>
      </van-row>
    </div>
    <div class="goBack" @click="back">
      <img src="/static/goBack.png" />
    </div>
  </div>
</template>

<script>
import { StepFour, projectDoctor } from '@/api/api'
import { mapActions, mapState } from 'vuex'
import Vue from 'vue'
import { appHost } from '@/utils/host'
import { parseUrl } from '@/utils/utils'
import { Toast } from 'vant'
var draw
var isDraw = false
var preHandler = function (e) {
  e.preventDefault()
}
class Draw {
  constructor(el) {
    this.el = el
    this.canvas = document.getElementById(this.el)
    this.cxt = this.canvas.getContext('2d')
    this.cw = document.getElementById('canvas-box').clientWidth - 30
    this.ch = document.getElementById('canvas-box').clientHeight - 30
    this.canvas.width = this.cw
    this.canvas.height = this.ch
    this.stage_info = canvas.getBoundingClientRect()
    this.path = {
      beginX: 0,
      beginY: 0,
      endX: 0,
      endY: 0
    }
  }
  init(btn) {
    var that = this

    this.canvas.addEventListener('touchstart', function (event) {
      document.addEventListener('touchstart', preHandler, false)
      that.drawBegin(event)
    })
    this.canvas.addEventListener('touchend', function (event) {
      document.addEventListener('touchend', preHandler, false)
      that.drawEnd()
    })
    this.clear(btn)
  }
  drawBegin(e) {
    var that = this
    window.getSelection() ? window.getSelection().removeAllRanges() : document.selection.empty()
    this.cxt.strokeStyle = '#000'
    this.cxt.lineWidth = 5
    this.cxt.beginPath()
    this.cxt.moveTo(e.changedTouches[0].clientX - this.stage_info.left, e.changedTouches[0].clientY - this.stage_info.top)
    this.path.beginX = e.changedTouches[0].clientX - this.stage_info.left
    this.path.beginY = e.changedTouches[0].clientY - this.stage_info.top
    canvas.addEventListener('touchmove', function () {
      that.drawing(event)
    })
  }
  drawing(e) {
    this.cxt.lineTo(e.changedTouches[0].clientX - this.stage_info.left, e.changedTouches[0].clientY - this.stage_info.top)
    this.path.endX = e.changedTouches[0].clientX - this.stage_info.left
    this.path.endY = e.changedTouches[0].clientY - this.stage_info.top
    this.cxt.stroke()
  }
  drawEnd() {
    document.removeEventListener('touchstart', preHandler, false)
    document.removeEventListener('touchend', preHandler, false)
    document.removeEventListener('touchmove', preHandler, false)
    //canvas.ontouchmove = canvas.ontouchend = null
    isDraw = true
  }
  clear(btn) {
    this.cxt.clearRect(0, 0, this.cw, this.ch)
    isDraw = false
  }
  save() {
    return canvas.toDataURL('image/png')
  }
}
export default {
  data() {
    return {
      baseFileUrl: appHost,
      signature_image: '',
      submitBtnDisabled: false,
      idcardBtnDisabled: false,
      project_doctor_id: null,
      show: true,
      short_name: ''
    }
  },
  computed: {
    ...mapState(['patientOne', 'userInfo', 'imgList', 'idcardList', 'certificationData', 'basicInformation'])
  },
  async created() {
    this.project_doctor_id = this.$route.query.project_doctor_id
  },
  mounted() {
    this.short_name = this.basicInformation.projects[0].short_name
    sessionStorage.removeItem('service_sign')
    draw = new Draw('canvas')
    draw.init()
    this.init()
  },
  methods: {
    async init() {
      let res = await projectDoctor()
      if (res.signature_image) {
        if (res.signature_image.indexOf(this.baseFileUrl) != -1) {
          this.signature_image = res.signature_image
        } else {
          this.signature_image = parseUrl(res.signature_image, this.baseFileUrl)
        }
        console.log('签名：', res.signature_image)
        this.show = false
      }
      // sessionStorage.removeItem('service_agreement')
    },
    clear() {
      this.submitBtnDisabled = false
      this.show = true
      draw.clear()
    },
    async save() {
      if (!isDraw && !this.signature_image) {
        Toast({
          message: '请先手写签名再点击”保存“',
          duration: 1000
        })
      } else {
        var data = draw.save()
        if (isDraw) this.signature_image = data
        let self = this
        if (!this.signature_image) {
          Toast('请先手写签名')
          return
        }
        self.submitBtnDisabled = true
        // return
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true
        })
        let req = {
          project_doctor_id: this.project_doctor_id,
          signature_image: this.signature_image,
          idcard_photos: this.idcardList
        }
        if (this.certificationData) {
          req = {
            ...req,
            sign_department: this.certificationData.sign_department,
            sign_user: this.certificationData.sign_user,
            certification_code: this.certificationData.certification_code,
            certification_date: this.certificationData.certification_date
          }
        }
        StepFour(req)
          .then(res => {
            console.log('StepFour信息：', res)
            self.submitBtnDisabled = false
            Toast.clear()
            sessionStorage.setItem('service_sign', res.service_agreement)
            if (this.short_name == 'bxzleq') {
              //靶向治疗二期需要先预览知情同意书页面
              sessionStorage.setItem('informed_consent_sign', res.informed_consent_sign)
              this.$router.replace({
                path: '/read_agreement',
                query: {
                  project_doctor_id: this.project_doctor_id
                }
              })
            } else {
              this.$router.replace({
                path: '/read_service_agreement',
                query: {
                  ...this.$route.query
                }
              })
            }
          })
          .catch(err => {
            self.submitBtnDisabled = false
            Toast(err.response.data.message)
          })
      }
    },
    mutate(word) {
      this.$emit('input', word)
    },
    touchmove() {
      document.body.addEventListener(
        'touchmove',
        function (e) {
          e.preventDefault()
          e.stopPropagation()
        },
        {
          passive: false
        }
      )
    },
    back() {
      this.$router.replace({
        path: '/service_agreement',
        query: {
          ...this.$route.query
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.before-apply {
  text-align: center;
  padding-bottom: 74px;
  height: 100vh;
  box-sizing: border-box;
  position: relative;

  .canvas-title {
    height: 48px;
    line-height: 48px;
    // font-size: 1.6rem;
    font-size: 16px;
    color: #232b30;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-family: PingFangSC-Medium;
    // &::after {
    //     .b-line;
    // }
  }

  .canvas-box {
    height: calc(100vh - 122px);
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    .canvas-tips {
      line-height: 20px;
      font-size: 13px;
      // background: rgba(@blue, 0.6);
      position: absolute;
      bottom: 14px;
      left: 15px;
      right: 15px;
      // color: gray;
      color: #ffffff;
      background: #7db6fa;
      padding: 4 0;
    }
  }

  #canvas {
    // background: lighten(#1E86F0, 40%) center center no-repeat;
    background: #e7eeff center center no-repeat;
    background-image: url('/static/broad_bg.png');
    background-size: auto 75%;
    // border: lighten(#1E86F0, 30%) solid 1px;
    border: 1px solid rgba(125, 182, 250, 1);
    cursor: default;
    margin: 15px auto;
  }

  .canvas-btn {
    padding: 0 15px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    background: #fff;
    z-index: 3002;
    box-sizing: border-box;
  }

  .idcard-btn {
    padding: 15px;
    background: #fff;
    z-index: 3003;
  }
}
</style>
